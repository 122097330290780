@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/7.0.0/normalize.min.css');

html, body {
    overflow-x: hidden;
    width: 100%;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
    font-smooth: auto;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: antialiased;
    background: transparent;
    font-size: 16px;
}

#App {
    display:flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    align-content: stretch;
    height: 100%;
    width:100%;
}
.MuiCardHeader-avatar a {
  text-decoration:none!important;
}

.colorField input { min-height: 40px; }

a {color:inherit;}

* {
    box-sizing: border-box;
}

sup { font-size:70% }
*::-webkit-scrollbar {
    width:0px !important
}

.scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scrollbar::-webkit-scrollbar:vertical {
    width: 8px;
}

.scrollbar::-webkit-scrollbar:horizontal {
    height: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .5);
}

@media all {
    .MuiGrid-grid-md-4 {flex: auto!important;}
}

@media all and (max-width: 600px) {
  .taForm .MuiGrid-grid-xs-6 {
    max-width: 100%!important;
    flex-grow: 1!important;
  }
}

#GroupEditDrawer .MuiGrid-grid-xs-12, #DrawerForm .MuiGrid-grid-xs-12 {
    max-width: 100%!important;
    flex-grow: 1!important;
    flex-basis:100%;
}

#GroupEditDrawer .MuiGrid-grid-xs-12 > div, #DrawerForm .MuiGrid-grid-xs-12 > div {
    margin:auto 2%;
    width:auto;
    min-width:96%;
}


audio { background: #f1f3f4}

/* used by ConfirmWager */
.collapse {
  display: block;
  max-height: 0px;
  overflow: hidden;
  transition: max-height .5s cubic-bezier(0, 1, 0, 1);
}
.collapse.show {
  max-height: 99em;
  transition: max-height .5s ease-in-out;
}



#activePlayerEl > div {
    width:100%!important;  height:auto;
}

#playerBar {
    transition: height .5s ease-in-out;
}


/* rotate playing buttons */
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.curPlaying.video svg, .curPlaying.audio svg, .curPlaying.youtube svg {
    animation-name:spin;
    animation-duration:1s;
    animation-delay:0s;
    animation-iteration-count:infinite;
    animation-direction:normal;
    animation-timing-function:linear;
    animation-fill-mode:both;
}

/* used by Cypress mouse emulator */
@keyframes pulse {
    0% {
        transform: scale(1, 1);
        opacity:.8
    }

    50% {
        transform: scale(.75, .75);
        opacity:1;
    }

    100% {
        transform: scale(1, 1);
        opacity:.8
    }
}

/* for PickupMVP bg */
@keyframes panBG {
    0% {
        background-position:0 70%;
    }
    100% {
        background-position:100% 70%;
    }
}

/* screensize borders */
/* The device with borders */
.mobile {
    position: relative;
    width: 100%;
    max-width:576px;
    margin: auto;
    border: 10px #000 solid;
    border-top-width: 30px;
    border-bottom-width: 30px;
    border-radius: 36px;
}

/* The horizontal line on the top of the device */
.mobile:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #969696;
    border-radius: 10px;
}

/* The circle on the bottom of the device */
.mobile:after {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 50%;
    bottom: -30px;
    transform: translate(-50%, -50%);
    background: #969696;
    border-radius: 50%;
}

/* The screen (or content) of the device */
.mobile .deviceMask {
    width:100%; height:100%;
    overflow: hidden;
    background: #000;
}


/* The device with borders */
.tablet {
    position: relative;
    width: 100%;
    min-width:576px;
    max-width:992px;
    margin: auto;
    border: 16px #000 solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
}

/* The horizontal line on the top of the device */
.tablet:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #969696;
    border-radius: 10px;
}

/* The circle on the bottom of the device */
.tablet:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #969696;
    border-radius: 50%;
}

/* The screen (or content) of the device */
.tablet .deviceMask {
    width:100%; height:100%;
    overflow: hidden;
    margin: -1px;
}


/* The desktop with borders */
.desktop {
    position:relative;
    width:100%;
    margin:auto auto 20px -5%;
    max-width:1024px;
    border-radius:6px 6px 0 0;
    border-style: solid;
    border-color: black;
    border-width: 16px 16px 45px 16px;
    background-color: black;
}

/* The keyboard of the desktop */
.desktop:after {
    content: '';
    display: block;
    position: absolute;
    bottom:-60px;
    width: 110%;
    margin:30px -5% 0 -5%;
    height: 30px;
    background: black;
    border-radius: 6px;

}

/* The top of the keyboard */
.desktop:before {
    content: '';
    display: block;
    position: absolute;
    width: 250px;
    height: 8px;
    bottom: -45px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #f1f1f1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1;
}

/* The screen (or content) of the device */
.desktop .deviceMask {
    width:100%; height:100%;
    overflow: hidden;
    border: none;
}

#cypressHand {
    position: absolute;
    opacity:.8;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    margin: auto;
    z-index: 999999999;
}

#cypressHand.pulse {
    animation-name:pulse;
    animation-duration:.5s;
    animation-delay:0s;
    animation-iteration-count:1;
    animation-direction:normal;
    animation-timing-function:ease-out;
    animation-fill-mode:both;
}

#cypressContextCloser {
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
}

.render-actions__ActionsWrapper-dp6lnv-0 {
    top: 5px!important;
    left: 5px!important;
    bottom: auto!important;
    right: auto!important;
}

.bubbleArrow {
    position: relative;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 6px;
    margin: 6px;
    padding: 5px;
}
.bubbleArrow::before {
    content: '';
    position:absolute;
    top: -14px;
    left:auto;
    right:13%;
    width: 0;
    height: 0;
    border:7px solid transparent;
    border-right-color: rgba(255, 255, 255, 0.15);
    border-bottom-color: rgba(255, 255, 255, 0.15);
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    justify-content: space-around;
    width:100%;
}
.my-masonry-grid_column {
    padding-left:1%;
    padding-right:1%;
    background-clip: padding-box;
}


@keyframes heartRateIn {
    0% {
        left: 0%;
    }
    50% {
        left: 50%;
    }
    100% {
        left: 100%;
    }
}


#notistack-snackbar {
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
